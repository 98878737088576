import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/register',
            name: 'register',
            component: require('@/components/pages/RegisterUser').default,
            meta: {
                title: 'Register new user',
                requiresAuth: false
            }
        },
        {
            path: '/delete-user-request',
            name: 'deleteUserRequest',
            component: require('@/components/pages/DeleteUserRequest').default,
            meta: {
                title: 'Delete user request',
                requiresAuth: false
            }
        },
        {
            path: '/register-organisation',
            name: 'registerOrganisation',
            component: require('@/components/pages/RegisterOrg').default,
            meta: {
                title: 'Register organisation',
                requiresAuth: false
            }
        },
        {
            path: '/share-live-view/:hash',
            name: 'shareLiveView',
            component: require('@/components/pages/liveViewShare/LiveViewShare.vue').default,
            meta: {
                title: 'Share live view',
                requiresAuth: false
            }
        },
        {
            path: '/errorGeneric',
            name: 'errorGeneric',
            component: require('@/components/pages/ErrorGeneric').default,
            meta: {
                title: 'Error',
                requiresAuth: false
            }
        },
        {
            path: '/',
            name: 'home',
            redirect: 'apps',
            component: require('@/components/pages/DefaultPage').default,
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: '/apps',
                    name: 'apps',
                    component: require('@/components/views/apps/AppsPage').default,
                    meta: {
                        titleKey: 'apps.title',
                        subtitleKey: 'apps.subtitle',
                        requiresAuth: true,
                        hideHeader: true
                    }
                },
                {
                    path: '/indoor-climate',
                    name: 'indoorClimate',
                    component: require('@/components/views/indoorClimate/IndoorClimate').default,
                    meta: {
                        titleKey: 'indoorClimate.title',
                        subtitleKey: 'indoorClimate.subtitle',
                        requiresAuth: true
                    }
                },
                {
                    path: '/graphs/location',
                    name: 'graphLocation',
                    component: require('@/components/views/graphs/location/GraphLocation').default,
                    meta: {
                        titleKey: 'graphs.location.title',
                        subtitleKey: 'graphs.location.subtitle',
                        requiresAuth: true
                    }
                },
                {
                    path: '/graphs/location-comparison',
                    name: 'graphLocationComparison',
                    component: require('@/components/views/graphs/locationComparison/GraphLocationComparison').default,
                    meta: {
                        titleKey: 'graphs.locationComparison.title',
                        subtitleKey: 'graphs.locationComparison.subtitle',
                        requiresAuth: true
                    }
                },
                {
                    path: '/graphs/metering-points',
                    name: 'graphMeteringPoints',
                    component: require('@/components/views/graphs/meteringPoints/GraphMeteringPoints').default,
                    meta: {
                        titleKey: 'graphs.meteringPoints.title',
                        subtitleKey: 'graphs.meteringPoints.subtitle',
                        requiresAuth: true
                    }
                },
                {
                    path: '/analytics/statistics',
                    name: 'analyticsStatistics',
                    component: require('@/components/views/analytics/statistics/StatisticsPage').default,
                    meta: {
                        titleKey: 'analytics.statistics.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/analytics/hourly-heatmaps',
                    name: 'analyticsHourlyHeatmaps',
                    component: require('@/components/views/analytics/hourlyHeatmaps/HourlyHeatmaps').default,
                    meta: {
                        titleKey: 'analytics.hourlyHeatmaps.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/analytics/heatmaps',
                    name: 'analyticsHeatmaps',
                    component: require('@/components/views/analytics/heatmaps/HeatmapsPage').default,
                    meta: {
                        titleKey: 'analytics.heatmap.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/feedback',
                    name: 'feedback',
                    component: require('@/components/views/feedback/FeedbackPage').default,
                    meta: {
                        titleKey: 'feedback.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/forms',
                    name: 'forms',
                    component: require('@/components/views/feedback/FormsPage').default,
                    meta: {
                        titleKey: 'feedback.forms.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/message-centre',
                    name: 'messageCentre',
                    component: require('@/components/views/messageCentre/MessageCentre').default,
                    meta: {
                        titleKey: 'messageCentre.title',
                        subtitleKey: 'messageCentre.subtitle',
                        requiresAuth: true
                    }
                },
                {
                    path: '/advices/new',
                    name: 'adviceNew',
                    component: require('@/components/views/messageCentre/MessageDetails').default,
                    meta: {
                        titleKey: 'messageCentre.advices.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/messages/:id',
                    name: 'messageDetails',
                    component: require('@/components/views/messageCentre/MessageDetails').default,
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    path: '/organisation',
                    name: 'organisation',
                    component: require('@/components/views/organisation/OrgDetails').default,
                    meta: {
                        titleKey: 'organisation.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/organisations/:id',
                    name: 'organisationDetails',
                    component: require('@/components/views/organisation/OrgDetails.vue').default,
                    meta: {
                        titleKey: 'organisation.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/organisation/new',
                    name: 'organisationNew',
                    component: require('@/components/views/organisation/OrgNew.vue').default,
                    meta: {
                        titleKey: 'organisation.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/organisation/register-organisation-requests',
                    name: 'registerOrganisationRequestList',
                    component: require('@/components/views/organisation/RegisterOrganisationRequestsList.vue').default,
                    meta: {
                        titleKey: 'orgAdmin.registerOrganisationRequests.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/organisation/register-organisation-requests/:id',
                    name: 'registerOrganisationRequestDetails',
                    component: require('@/components/views/organisation/RegisterOrganisationRequestDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.registerOrganisationRequest.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/user-management',
                    name: 'userManagement',
                    component: require('@/components/views/userManagement/UserManagement').default,
                    meta: {
                        titleKey: 'userManagement.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/users/new',
                    name: 'userNew',
                    component: require('@/components/views/userManagement/UserDetails.vue').default,
                    meta: {
                        titleKey: 'orgAdmin.user.new.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/users/:id',
                    name: 'userDetails',
                    component: require('@/components/views/userManagement/UserDetails.vue').default,
                    meta: {
                        titleKey: 'orgAdmin.user.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/register-user-requests/new',
                    name: 'registerUserRequestNew',
                    component: require('@/components/views/userManagement/RegisterUserRequestDetails.vue').default,
                    meta: {
                        titleKey: 'orgAdmin.registerUserRequest.new.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/register-user-requests/:id',
                    name: 'registerUserRequestDetails',
                    component: require('@/components/views/userManagement/RegisterUserRequestDetails.vue').default,
                    meta: {
                        titleKey: 'orgAdmin.registerUserRequest.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/buildings',
                    name: 'buildings',
                    component: require('@/components/views/buildings/BuildingList').default,
                    meta: {
                        titleKey: 'buildings.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/buildings/new',
                    name: 'buildingNew',
                    component: require('@/components/views/buildings/BuildingNew').default,
                    meta: {
                        titleKey: 'buildings.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/buildings/:id',
                    name: 'buildingDetails',
                    component: require('@/components/views/buildings/BuildingDetails').default,
                    meta: {
                        titleKey: 'buildings.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/devices',
                    name: 'devices',
                    component: require('@/components/views/devices/DevicesPage').default,
                    meta: {
                        titleKey: 'devices.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix(admin)?/devices/:id',
                    name: 'deviceDetails',
                    component: require('@/components/views/admin/system/DeviceDetails').default,
                    meta: {
                        titleKey: 'systemAdmin.device.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/integrations',
                    name: 'integrations',
                    component: require('@/components/views/integrations/IntegrationsPage').default,
                    meta: {
                        titleKey: 'integrations.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/integrations/new',
                    name: 'integrationNew',
                    component: require('@/components/views/integrations/IntegrationDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.integration.new.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/integrations/:id',
                    name: 'integrationDetails',
                    component: require('@/components/views/integrations/IntegrationDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.integration.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/profiles',
                    name: 'locationProfiles',
                    component: require('@/components/views/profiles/ProfilesPage').default,
                    meta: {
                        titleKey: 'profiles.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/location-profiles/new',
                    name: 'locationProfileNew',
                    component: require('@/components/views/profiles/LocationProfileDetails').default,
                    meta: {
                        titleKey: 'profiles.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/location-profiles/:id',
                    name: 'locationProfileDetails',
                    component: require('@/components/views/profiles/LocationProfileDetails').default,
                    meta: {
                        titleKey: 'profiles.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/schedulers',
                    name: 'schedulerProfiles',
                    component: require('@/components/views/schedulers/SchedulersPage').default,
                    meta: {
                        titleKey: 'schedulers.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/scheduler-profiles/new',
                    name: 'schedulerProfileNew',
                    component: require('@/components/views/schedulers/SchedulerProfileDetails').default,
                    meta: {
                        titleKey: 'schedulers.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/scheduler-profiles/:id',
                    name: 'schedulerProfileDetails',
                    component: require('@/components/views/schedulers/SchedulerProfileDetails').default,
                    meta: {
                        titleKey: 'schedulers.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/system',
                    name: 'systemAdmin',
                    component: require('@/components/views/admin/system/SystemAdmin').default,
                    meta: {
                        titleKey: 'systemAdmin.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/device-models/new',
                    name: 'deviceModelNew',
                    component: require('@/components/views/admin/system/DeviceModelDetails').default,
                    meta: {
                        titleKey: 'systemAdmin.deviceModel.new.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/device-models/:id',
                    name: 'deviceModelDetails',
                    component: require('@/components/views/admin/system/DeviceModelDetails').default,
                    meta: {
                        titleKey: 'systemAdmin.deviceModel.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/organisations',
                    name: 'organisationAdmin',
                    component: require('@/components/views/admin/organisations/OrgAdmin').default,
                    meta: {
                        titleKey: 'orgAdmin.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/organisations/:id',
                    name: 'organisationDetailsAdmin',
                    component: require('@/components/views/admin/organisations/OrgDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.organisation.name',
                        headerComponent: require('@/components/views/admin/organisations/OrgDetailsHeader').default,
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/buildings/:id',
                    name: 'buildingDetailsAdmin',
                    component: require('@/components/views/admin/organisations/BuildingDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.building.name',
                        headerComponent: require('@/components/views/admin/organisations/BuildingDetailsHeader').default,
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/maps/new',
                    name: 'mapNew',
                    component: require('@/components/views/buildings/MapNew').default,
                    meta: {
                        titleKey: 'orgAdmin.map.name',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/maps/:id',
                    name: 'mapDetails',
                    component: require('@/components/views/buildings/MapDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.map.name',
                        headerComponent: require('@/components/views/buildings/MapDetailsHeader').default,
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/forms/new',
                    name: 'formNew',
                    component: require('@/components/views/feedback/FormDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.form.new.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/forms/:id',
                    name: 'formDetails',
                    component: require('@/components/views/feedback/FormDetails').default,
                    meta: {
                        titleKey: 'orgAdmin.form.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/share-data/',
                    name: 'shareData',
                    component: require('@/components/views/shareData/ShareData').default,
                    meta: {
                        titleKey: 'shareData.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/share-links/new',
                    name: 'shareLinkNew',
                    component: require('@/components/views/shareData/ShareLinkDetails').default,
                    meta: {
                        titleKey: 'shareData.create.title',
                        requiresAuth: true
                    }
                },
                {
                    path: ':prefix?/share-links/:id',
                    name: 'shareLinkDetails',
                    component: require('@/components/views/shareData/ShareLinkDetails.vue').default,
                    meta: {
                        titleKey: 'shareData.details.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/admin/test-external-api',
                    name: 'TestExternalApi',
                    component: require('@/components/views/admin/test/TestExternalApi.vue').default,
                    meta: {
                        titleKey: 'Test external API endpoints',
                        requiresAuth: true
                    }
                },
                {
                    path: '/user-settings',
                    name: 'user-settings',
                    component: require('@/components/views/user/UserSettings').default,
                    meta: {
                        titleKey: 'userSettings.title',
                        requiresAuth: true
                    }
                },
                {
                    path: '/reporting',
                    name: 'reporting',
                    component: require('@/components/views/reporting/ReportingPage').default,
                    meta: {
                        titleKey: 'reporting.title',
                        requiresAuth: true
                    }
                },

                {
                    path: '/buildings/:buildingId/logs/:logId',
                    name: 'view-log',
                    component: require('@/components/views/buildings/BuildingLogDetails').default,
                    props: route => ({ buildingId: Number(route.params.buildingId), logId: route.params.logId }),
                    meta: {
                      titleKey: 'orgAdmin.building.logs.detailsTitle',
                      requiresAuth: true,
                    },
                  },
                  {
                    path: '/buildings/:buildingId/logs/new',
                    name: 'new-log',
                    component: require('@/components/views/buildings/BuildingLogDetails').default,
                    props: route => ({ buildingId: Number(route.params.buildingId), logId: 'new' }),
                    meta: {
                      titleKey: 'orgAdmin.building.logs.detailsTitle',
                      requiresAuth: true,
                    },
                  },
                  {
                    path: '/organisation/logs',
                    name: 'org-logs',
                    component: require('@/components/views/organisation/OrgLogs').default,
                    meta: {
                      titleKey: 'orgAdmin.building.logs.orgList',
                      requiresAuth: true,
                    },
                  },
            ]
        }
    ]
})

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(async (to, from, next) => {

    //console.log('(main.js.routing hook) from: ', from, ', to: ', to)

    if (to.matched.some(record => record.meta.requiresAuth)) {

        // We wait for Keycloak init and login to backend, then we can call all methods safely
        while (router.app.$keycloak.createLoginUrl === null) {
            await sleep(200)
        }
        // We wait for backend login for max of 1s
        let count = 0
        while (!store.getters.isLoggedIn && count++ < 5) {
            await sleep(200)
        }

        if (router.app.$keycloak.authenticated) {
            next()
        } else {
            const loginUrl = router.app.$keycloak.createLoginUrl()
            window.location.replace(loginUrl)
        }
    } else {
        next()
    }
})

// Set the page title
router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Climify'
    }
})

export default router
