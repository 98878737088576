<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-6">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.integration.attributes.alias" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-device-alias" :label="$t('orgAdmin.integration.attributes.alias')" label-for="input-integration-alias" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-integration-alias"
                            alias="input-integration-alias"
                            v-model="form.alias"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-integration-alias"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-integration-alias">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="orgAdmin.integration.attributes.integrationType" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-integration-type" :label="$t('orgAdmin.integration.attributes.integrationType')" label-for="input-integration-type" label-cols-sm="2">
                        <b-form-input :plaintext="true" v-if="form.integrationType && type=='view'" :value="integrationTypes.filter(item => { return item.id == form.integrationType })[0].text" />
                        <b-form-select
                            v-if="type!='view'"
                            :plain="true"
                            id="input-integration-type"
                            name="input-integration-type"
                            v-model="form.integrationType"
                            :options="integrationTypes"
                            value-field="id"
                            :state="getValidationState(validationContext)"
                        ></b-form-select>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <div v-if="form.integrationType == 'CHIRPSTACK'">
                      <validation-provider name="orgAdmin.integration.attributes.chirpstackURL" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-chirpstack-url" :label="$t('orgAdmin.integration.attributes.chirpstackURL')" label-for="input-integration-chirpstack-url" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-chirpstack-url"
                              name="input-integration-chirpstack-url"
                              v-model="form.chirpstackUrl"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-chirpstack-url"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-chirpstack-url">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.chirpstackToken" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-chirpstack-token" :label="$t('orgAdmin.integration.attributes.chirpstackToken')" label-for="input-integration-chirpstack-token" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-chirpstack-token"
                              name="input-integration-chirpstack-token"
                              v-model="form.chirpstackToken"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-chirpstack-token"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-chirpstack-token">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.chirpstackAppId" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-chirpstack-token" :label="$t('orgAdmin.integration.attributes.chirpstackAppId')" label-for="input-integration-chirpstack-app-id" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-chirpstack-app-id"
                              name="input-integration-chirpstack-app-id"
                              v-model="form.chirpstackAppId"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-chirpstack-app-id"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-chirpstack-app-id">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div v-if="form.integrationType == 'DANFOSS'">
                      <validation-provider name="orgAdmin.integration.attributes.danfossClientId" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-danfoss-client-id" :label="$t('orgAdmin.integration.attributes.danfossClientId')" label-for="input-integration-danfoss-client-id" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-danfoss-client-id"
                              name="input-integration-danfoss-client-id"
                              v-model="form.danfossClientId"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-danfoss-client-id"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-danfoss-client-id">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.danfossSecret" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-danfoss-secret" :label="$t('orgAdmin.integration.attributes.danfossSecret')" label-for="input-integration-danfoss-secret" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-danfoss-secret"
                              name="input-integration-danfoss-secret"
                              v-model="form.danfossSecret"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-danfoss-secret"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-danfoss-secret">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div v-if="form.integrationType == 'MQTT'">
                      <validation-provider name="orgAdmin.integration.attributes.mqttURL" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-mqtt-url" :label="$t('orgAdmin.integration.attributes.mqttURL')" label-for="input-integration-mqtt-url" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-mqtt-url"
                              name="input-integration-mqtt-url"
                              v-model="form.mqttUrl"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-mqtt-url"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-mqtt-url">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.mqttAppId" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-mqtt-token" :label="$t('orgAdmin.integration.attributes.mqttAppId')" label-for="input-integration-mqtt-app-id" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-mqtt-app-id"
                              name="input-integration-mqtt-app-id"
                              v-model="form.mqttAppId"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-mqtt-app-id"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-mqtt-app-id">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div v-if="form.integrationType == 'OS2IOT'">
                      <validation-provider name="orgAdmin.integration.attributes.os2IotURL" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-os2iot-url" :label="$t('orgAdmin.integration.attributes.os2IotURL')" label-for="input-integration-os2iot-url" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-os2iot-url"
                              name="input-integration-os2iot-url"
                              v-model="form.os2IotUrl"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-os2iot-url"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-os2iot-url">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.os2IotAPIKey" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-os2iot-token" :label="$t('orgAdmin.integration.attributes.os2IotAPIKey')" label-for="input-integration-os2iot-token" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-os2iot-token"
                              name="input-integration-os2iot-token"
                              v-model="form.os2IotAPIKey"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-os2iot-token"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-os2iot-token">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.os2IotAppId" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-os2iot-token" :label="$t('orgAdmin.integration.attributes.os2IotAppId')" label-for="input-integration-os2iot-app-id" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-os2iot-app-id"
                              name="input-integration-os2iot-app-id"
                              v-model="form.os2IotAppId"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-os2iot-app-id"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-os2iot-app-id">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div v-if="form.integrationType === 'ELOVERBLIK' && form.id && !form.authorized">
                      <div class="pb-3">
                          <a class="btn btn-warning text-primary" :href="generateEloverblikUrl()">Authorize integration with Eloverblik</a>
                      </div>
                    </div>

                    <div v-if="form.integrationType == 'CUSTOM'">
                      <validation-provider name="orgAdmin.integration.attributes.customURL" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-custom-url" :label="$t('orgAdmin.integration.attributes.customURL')" label-for="input-integration-custom-url" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-custom-url"
                              name="input-integration-custom-url"
                              v-model="form.customUrl"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-custom-url"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-custom-url">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.customToken" :rules="{ required: false }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-custom-token" :label="$t('orgAdmin.integration.attributes.customToken')" label-for="input-integration-custom-token" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-custom-token"
                              name="input-integration-custom-token"
                              v-model="form.customToken"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-custom-token"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-custom-token">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider name="orgAdmin.integration.attributes.customAppId" :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group id="input-group-integration-custom-token" :label="$t('orgAdmin.integration.attributes.customAppId')" label-for="input-integration-custom-app-id" label-cols-sm="2">
                          <b-form-input
                              :plaintext="type=='view'"
                              id="input-integration-custom-app-id"
                              name="input-integration-custom-app-id"
                              v-model="form.customAppId"
                              :state="getValidationState(validationContext)"
                              aria-describedby="input-feedback-integration-custom-app-id"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-feedback-integration-custom-app-id">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                  </div>

                </div>

                <div class="row">
                  <div class="col-12">
                    <button  type="button" class="btn btn-sm btn-primary" v-if="type=='view'" @click.prevent="type='edit'">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('common.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="type!='create'" class="btn btn-sm btn-danger float-right" @click="deleteIntegration">{{ $t('common.actions.delete') }}</button>
                  </div>
                </div>

              </b-form>
            </validation-observer>

            <div v-if="form.integrationType == 'DANFOSS' && type!='create'">
              <h4 class="mt-5 mb-4">Sync devices</h4>
              <p>{{ syncResult }}</p>
              <button type="button" class="btn btn-sm btn-primary" @click="syncDevicesForIntegration">{{ $t('orgAdmin.integration.actions.sync') }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import * as commons from "@/commons";
import i18n from "@/i18n";
import {formatDateToDateTimeByLocale} from "@/filters";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      error: null,
      type: 'view',
      form: {
        alias: '',
        integrationType: null,
        chirpstackUrl: '',
        chirpstackAppId: '',
        chirpstackToken: '',
        danfossClientId: '',
        danfossSecret: '',
        mqttUrl: '',
        mqttAppId: '',
        os2IotUrl: '',
        os2IotAppId: '',
        os2IotAPIKey: '',
        customUrl: '',
        customToken: '',
        customAppId: ''
      },
      syncResult: ''
    }
  },
  created() {
    console.log('integrationDetails $route.name: ' + this.$route.name + ', this.$route.params.prefix ' + this.$route.params.prefix)
    this.type = this.$route.name == 'integrationNew' ? 'create' : 'view'
    if(this.type === 'view') {
      this.$store.dispatch('integrations/fetchIntegrationDetails', this.$route.params.id)
        .then(
          response => {
            if(this.$route.query.authorized && !response.data.authorized){
              this.$store.dispatch('integrations/activateIntegration', this.$route.params.id)
                .then(
                    response => this.$toastr.i(this.$i18n.t('orgAdmin.integration.info.authorized')),
                    error => this.$toastr.i(this.$i18n.t('orgAdmin.integration.info.authorizationError')),
                );
            }
          },
          error => console.log("Error: " + error)
        )
    } else {
      this.$store.commit('integrations/SAVE_INTEGRATION_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      integration: state => state.integrations.integrationDetails.entity,
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    integrationTypes() {
      return [
        { id: "CHIRPSTACK", text: i18n.t("orgAdmin.integration.integrationType.chirpstack") },
        { id: "DANFOSS", text: i18n.t("orgAdmin.integration.integrationType.danfoss") },
        { id: "MQTT", text: i18n.t("orgAdmin.integration.integrationType.mqtt") },
        { id: "OS2IOT", text: i18n.t("orgAdmin.integration.integrationType.os2Iot") },
        { id: "ELOVERBLIK", text: i18n.t("orgAdmin.integration.integrationType.eloverblik") },
        { id: "CUSTOM", text: i18n.t("orgAdmin.integration.integrationType.custom") },
      ]
    }
  },
  watch: {
    integration: function(newVal, oldVal) { // watch it
      console.log('Prop integration changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      if(this.integration){
        this.form.id = this.integration.id
        this.form.alias = this.integration.alias
        this.form.integrationType = this.integration.integrationType
        this.form.userDefined = this.integration.userDefined
        this.form.authorized = this.integration.authorized
        if(this.integration.settings) {
          this.form.chirpstackUrl = this.integration.settings.chirpstackUrl
          this.form.chirpstackAppId = this.integration.settings.chirpstackAppId
          this.form.chirpstackToken = this.integration.settings.chirpstackToken
          this.form.danfossClientId = this.integration.settings.danfossClientId
          this.form.danfossSecret = this.integration.settings.danfossSecret
          this.form.mqttUrl = this.integration.settings.mqttUrl
          this.form.mqttAppId = this.integration.settings.mqttAppId
          this.form.os2IotUrl = this.integration.settings.os2IotUrl
          this.form.os2IotAppId = this.integration.settings.os2IotAppId
          this.form.os2IotAPIKey = this.integration.settings.os2IotAPIKey
          this.form.customUrl = this.integration.settings.customUrl
          this.form.customToken = this.integration.settings.customToken
          this.form.customAppId = this.integration.settings.customAppId
        }
      } else {
        this.form.alias = ''
        this.form.integrationType = null
        this.form.userDefined = false
        this.form.authorized = false
        this.form.chirpstackUrl = ''
        this.form.chirpstackAppId = ''
        this.form.chirpstackToken = ''
        this.form.danfossClientId = ''
        this.form.danfossSecret = ''
        this.form.mqttUrl = ''
        this.form.mqttAppId = ''
        this.form.os2IotUrl = ''
        this.form.os2IotAppId = ''
        this.form.os2IotToken = ''
        this.form.customUrl = ''
        this.form.customToken = ''
        this.form.customAppId = ''
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    generateEloverblikUrl(){
      const thirdParty = "94256358-7125-499b-ad23-a6f8770d8f8d";
      let fromDate = new Date();
      fromDate.setFullYear(fromDate.getFullYear() - 4);
      fromDate = fromDate.toISOString().slice(0, 10);
      let toDate = new Date();
      toDate.setFullYear(toDate.getFullYear() + 3);
      toDate = toDate.toISOString().slice(0, 10)
      const customerKey = "org-" + this.organisation.id;
      const returnUrl = encodeURIComponent(window.location.href.replace(/(^\w+:|^)\/\//, '') + "?authorized=true");
      return `https://eloverblik.dk/authorization/authorization?thirdPartyId=${thirdParty}&fromDate=${fromDate}&toDate=${toDate}&customerKey=${customerKey}&returnUrl=${returnUrl}`;
    },
    cancel(){
      this.goBack()
    },
    submit() {
      this.error = ""
      this.info = ""
      let self = this

      const payload = {
        alias: this.form.alias,
        integrationType: this.form.integrationType,
        organisation: this.organisation,
        userDefined: this.form.userDefined
      };

      if (this.form.integrationType === 'CHIRPSTACK') {
        payload.settings = {
          chirpstackUrl: this.form.chirpstackUrl,
          chirpstackAppId: this.form.chirpstackAppId,
          chirpstackToken: this.form.chirpstackToken
        }
      } else if (this.form.integrationType === 'DANFOSS') {
        payload.settings = {
          danfossClientId: this.form.danfossClientId,
          danfossSecret: this.form.danfossSecret
        }
      } else if (this.form.integrationType === 'MQTT') {
        payload.settings = {
          mqttUrl: this.form.mqttUrl,
          mqttAppId: this.form.mqttAppId
        }
      } else if (this.form.integrationType === 'OS2IOT') {
        payload.settings = {
          os2IotUrl: this.form.os2IotUrl,
          os2IotAppId: this.form.os2IotAppId,
          os2IotAPIKey: this.form.os2IotAPIKey
        }
      } else if (this.form.integrationType === 'CUSTOM') {
        payload.settings = {
          customUrl: this.form.customUrl,
          customToken: this.form.customToken,
          customAppId: this.form.customAppId
        }
      }

      if(this.type=="create") {
        Vue.axios.post('/admin/integrations', JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('orgAdmin.integration.info.created'))
          self.$router.replace({
            name: 'integrationDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('integrations/fetchIntegrationDetails', this.$route.params.id)
          self.type = 'view'
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else if(this.type=="edit") {
        Vue.axios.put('/admin/integrations/' + this.form.id, JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('orgAdmin.integration.info.saved'))
          self.$store.dispatch('integrations/fetchIntegrationDetails', this.$route.params.id)
          self.type = 'view'
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    deleteIntegration(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.integration.confirm.delete'),
        callbackYes: function(){
          Vue.axios.delete('/admin/integrations/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.integration.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    syncDevicesForIntegration(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.integration.confirm.sync'),
        callbackYes: function(){
          Vue.axios.post('/admin/integrations/' + self.form.id + '/sync-devices')
              .then(response => {
                console.log("0")
                self.$toastr.i(i18n.t('orgAdmin.integration.info.syncCompleted'))
                console.log("1")
                self.syncResult = i18n.t('orgAdmin.integration.info.syncResult',
                    { 0: formatDateToDateTimeByLocale(response.data.timeOfSync), 1: response.data.numberOfCreated, 2: response.data.numberOfUpdated, 3: response.data.numberOfDeleted })
                console.log("2")
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>