<template>
  <div>

    <div class="alert alert-info" v-if="info">{{ info }}</div>

    <h4 class="mb-4">{{ $t('orgAdmin.location.name') }}</h4>

    <validation-observer ref="form" v-slot="{ handleSubmit }">

      <b-form role="form" @submit.stop.prevent="handleSubmit(submit)">

        <div class="row">

          <div class="col-12">

            <b-form-group v-if="location.status" id="input-group-user-status" :label="$t('orgAdmin.user.attributes.status')" label-for="input-resource-status" label-cols-sm="3">
              <span class="label" :class="[(location.status == 'BLOCKED' || location.status == 'PARENT_BLOCKED') ? 'label-danger' : 'label-primary']">{{ $i18n.t('enums.ResourceStatus.' + location.status) }}</span>
            </b-form-group>

            <validation-provider name="orgAdmin.location.attributes.name" :rules="{ required: true, min: 1, max: 100 }" v-slot="validationContext">
              <b-form-group id="input-group-location-name" :label="$t('orgAdmin.location.attributes.name')" label-for="input-location-name" label-cols-sm="3">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-location-name"
                    name="input-location-name"
                    v-model.trim="form.name"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-location-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-location-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider name="orgAdmin.location.attributes.nameAlternate" :rules="{ min: 1, max: 100 }" v-slot="validationContext">
              <b-form-group id="input-group-location-name-alternate" :label="$t('orgAdmin.location.attributes.nameAlternate')" label-for="input-location-name-alternate" label-cols-sm="3">
                <b-form-input
                    :plaintext="type=='view'"
                    id="input-location-name-alternate"
                    name="input-location-name-alternate"
                    v-model.trim="form.nameAlternate"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-feedback-location-name-alternate"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-location-name-alternate">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group id="input-group-location-profile" :label="$t('orgAdmin.location.attributes.locationProfile')" label-for="input-assign-location-profile" label-cols-sm="3">
              <v-select
                  :disabled="type=='view'"
                  id="input-assign-location-profile"
                  name="input-assign-location-profile"
                  v-model="form.locationProfile"
                  :options="locationProfiles"
                  label="name"
                  :clearable="true"
              />
            </b-form-group>

            <b-form-group id="input-group-scheduler-profile" :label="$t('orgAdmin.location.attributes.schedulerProfile')" label-for="input-assign-scheduler-profile" label-cols-sm="3">
              <v-select
                  :disabled="type=='view'"
                  id="input-assign-scheduler-profile"
                  name="input-assign-scheduler-profile"
                  v-model="form.schedulerProfile"
                  :options="schedulerProfiles"
                  label="name"
                  :clearable="true"
              />
            </b-form-group>

             <b-form-group id="input-group-location-qr-hash" :label="$t('orgAdmin.location.attributes.qrcode')" label-for="input-location-qr-hash" label-cols-sm="3">
              <b-form-checkbox :disabled="type === 'view'" v-model="isQRChecked"></b-form-checkbox>
              <qrcode-vue
                v-if="form.qrHash"
                :value="qrCodeUrl"
                :size="256"
                class="qr-code"
              ></qrcode-vue>
            </b-form-group>

            <div class="mt-4 mb-2">
              <label v-if="type=='create'">{{ $t('orgAdmin.location.actions.createPoints') }}</label>
              <label v-if="type=='edit'">{{ $t('orgAdmin.location.actions.editPoints') }}</label>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-sm btn-primary" v-if="canEdit && type=='view' && location.status=='ACTIVE'" @click.prevent="enterEditMode">
              <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.location.actions.edit') }}
            </button>
            <button type="submit" v-if="type=='create'" class="btn btn-sm btn-primary">{{ $t('common.actions.create') }}</button>
            <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
            <button type="button" v-if="type=='create' || type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
            <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel">{{ $t('common.actions.cancel') }}</button>
            <button type="button" v-if="type=='edit' && location.status=='ACTIVE'" class="btn btn-sm btn-danger float-right" @click="blockLocation">{{ $t('common.actions.block') }}</button>
            <button type="button" v-if="type!='create' && location.status=='BLOCKED'" class="btn btn-sm btn-danger float-right" @click="unblockLocation">{{ $t('common.actions.unblock') }}</button>
          </div>
        </div>
      </b-form>

    </validation-observer>


  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n";
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    QrcodeVue
  },
  props: {
    map: {
      type: Object
    },
    location: {
      type: Object
    },
    locationProfiles: {
      type: Array,
      default: () => []
    },
    schedulerProfiles: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean
    },
    initialType: {
      validator: function (value) {
        return ['view', 'edit', 'create'].includes(value)
      }
    },
    successCallback: {
      type: Function
    },
    cancelCallback: {
      type: Function
    },
    fetchPoints: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      info: null,
      type: this.initialType,
      form: {
        name: '',
        nameAlternate: '',
        locationProfile: null,
        schedulerProfile: null,
        qrHash: '',
        points: []
      },
    }
  },
  mounted() {
    console.log('Mounted')
    this.reset()
  },
  computed: {
    isQRChecked: {
      get() {
        return !!this.form.qrHash;
      },
      set(value) {
        if (!value) {
          this.form.qrHash = ''; 
        } else if (!this.form.qrHash) {
            this.form.qrHash = 'true'
        }
      }
    },
    qrCodeUrl() {
      const hostname = window.location.hostname;
      const domainParts = hostname.split('.');
      if (domainParts.length > 2) {
        const domain = domainParts.slice(-2).join('.');
        const subdomain = domainParts[domainParts.length - 3];
        return `https://feedback.${subdomain}.${domain}/?${this.form.qrHash}`;
      }
      return '';
    }
  },
  watch: {
    location: function(newVal, oldVal) {
      console.log('Prop location changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    enterEditMode() {
      this.type='edit'
      this.$emit('edit-mode')
    },
    reset() {
      if(this.location){
        this.form = Object.assign({}, this.location)
      }
      this.$refs.form.reset()
    },
    cancel(){
      this.reset()
      this.type = this.initialType
      if(this.cancelCallback){
        this.cancelCallback()
      }
    },
    blockLocation(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.location.confirm.block', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/locations/' + self.form.id + '/block')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.location.info.blocked'))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    unblockLocation(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.location.confirm.unblock', { '0': self.form.name }),
        callbackYes: function(){
          Vue.axios.put('/admin/locations/' + self.form.id + '/unblock')
              .then(response => {
                self.reset()
                self.type = self.initialType
                self.$toastr.i(i18n.t('orgAdmin.location.info.unblocked'))
                if (self.successCallback) {
                  self.successCallback()
                }
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    submit() {
      this.error = ""
      this.info = ""
      let vm = this

      if(this.type=="create") {

        this.form.map = this.location.map
        this.form.points = this.fetchPoints()
        this.form.qrHash = this.isQRChecked ? 'true' : null;

        Vue.axios.post('/admin/locations', JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          vm.reset()
          vm.type = vm.initialType
          vm.$toastr.i(vm.$i18n.t('orgAdmin.location.info.created'))

          if (vm.successCallback) {
            vm.successCallback()
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else if(this.type=="edit") {

        this.form.points = this.fetchPoints()
        this.form.qrHash = this.isQRChecked ? 'true' : null;

        Vue.axios.put('/admin/locations/' + this.form.id, JSON.stringify(this.form),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          vm.reset()
          vm.type = vm.initialType
          vm.$toastr.i(vm.$i18n.t('orgAdmin.location.info.saved'))

          if (vm.successCallback) {
            vm.successCallback()
          }
        }).catch(error => {
          if (error.response.status === 422) {
            vm.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    }
  }
}
</script>