<template>
  <div>

    <div class="form-group row m-b-md" v-if="items.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="items.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="items.length && items.length > table.size">
        <b-pagination v-if="items.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="items.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive _hover _striped
                 v-if="items.length != 0"
                 _class="text-nowrap"
                 thead-class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="items"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(externalId)="data">
            <b-link :to="{ name: 'deviceDetails', params: { id: data.item.id } }">{{ data.value }}</b-link>
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

      </div>
    </div>

  </div>
</template>

<script>
import RowCount from '@/components/common/RowCount';
import moment from 'moment-timezone'

export default {
  components: {
    RowCount
  },
  props: {
    items: {
      type: Array
    },
    routePrefix: {
      default: ''
    }
  },

  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'externalId',
            label: this.$t('systemAdmin.device.attributes.externalId'),
            sortable: true,
          },
          {
              key: 'deviceModelType',
              label: this.$t('systemAdmin.device.attributes.deviceModel.type'),
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
              formatter: (value, key, item) => {
                  return this.$t('enums.DeviceModelType.' + item.deviceModel.type)
              }
          },
          {
            key: 'alias',
            label: this.$t('systemAdmin.device.attributes.alias'),
            sortable: true,
          },
          {
            key: 'deviceModelManufacturer',
            label: this.$t('systemAdmin.device.attributes.deviceModel.manufacturer'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.deviceModel.manufacturer
            }
          },
          {
            key: 'deviceModelName',
            label: this.$t('systemAdmin.device.attributes.deviceModel.name'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.deviceModel.name
            }
          },
          {
            key: 'uuid',
            label: this.$t('systemAdmin.device.attributes.parameters.uuid'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.parameters.uuid
            }
          },
          {
            key: 'major',
            label: this.$t('systemAdmin.device.attributes.parameters.major'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.parameters.major
            }
          },
          {
            key: 'minor',
            label: this.$t('systemAdmin.device.attributes.parameters.minor'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.parameters.minor
            }
          },
          {
            key: 'mac',
            label: this.$t('systemAdmin.device.attributes.parameters.mac'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.parameters.mac
            }
          },
          {
            key: 'organisation',
            label: this.$t('systemAdmin.device.attributes.organisation'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.organisation ? item.organisation.name : ''
            }
          },
          {
            key: 'building',
            label: this.$t('systemAdmin.device.attributes.building'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.building ? item.building.name : ''
            }
          },
          {
              key: 'map',
              label: this.$t('systemAdmin.device.attributes.map'),
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
              formatter: (value, key, item) => {
                  return item.map ? item.map.name : ''
              }
          },
          {
            key: 'location',
            label: this.$t('systemAdmin.device.attributes.location'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.location ? `${item.location.name}${item.location.nameAlternate ? ` (${item.location.nameAlternate})` : ''}` : ''
            }
          },
        ]
      }
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    }
  }
}
</script>
