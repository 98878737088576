<template>
  <div>
    <div class="row mt-2 mb-4">
      <div class="input-group col-lg-6 align-content-center">
        <span class="input-group-prepend">
          <b-button class="btn btn-sm btn-primary" @click="clearFilter">{{ $t('reporting.reports.clear') }}</b-button>
        </span>
        <b-form-input
          type="text"
          debounce="300"
          max="50"
          :placeholder="$t('enterForSearch')"
          v-model="table.filter"
          class="form-control form-control-sm"
        />
        <span class="input-group-append">
          <b-button class="btn btn-sm btn-primary" @click="refreshLogs">
            <i class="fas fa-refresh mr-1"></i>
            <span>{{ $t('reporting.reports.refresh') }}</span>
          </b-button>
        </span>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="logs.length"></row-count>
      </div>
      <div class="col-lg-3 text-right" v-if="logs.length && logs.length > table.size">
        <b-pagination
          v-if="logs.length"
          size="sm"
          class="m-t-xs"
          align="right"
          :total-rows="logs.length"
          v-model="table.page"
          :per-page="table.size"
        ></b-pagination>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-lg-12">
        <b-table
          responsive
          hover
          striped
          v-if="logs && logs.length"
          class="text-nowrap"
          thead-class="text-nowrap"
          :filter="table.filter"
          :fields="table.fields"
          :current-page="table.page"
          :per-page="table.size"
          :items="processedLogs"
          :sort-by.sync="table.sortBy"
          :sort-desc.sync="table.sortDesc"
          @row-clicked="viewLogDetails"
        >
          <template #cell(id)="data">
            <span>{{ data.item.id }}</span>
          </template>
          <template #cell(buildingName)="data">
            <span>{{ data.item.building.name }}</span>
          </template>
          <template #cell(title)="data">
            <span>{{ data.item.title }}</span>
          </template>
          <template #cell(tags)="data">
            <div class="tags-circles">
              <div 
                v-for="tag in data.item.tags" 
                :key="tag" 
                class="tag-circle"
                :title="getTagLabel(tag)"
                :style="{ backgroundColor: getColorForTag(tag) }"
              >
                <div class="tag-tooltip">{{ getTagLabel(tag) }}</div>
              </div>
            </div>
          </template>
          <template #cell(description)="data">
            <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
              <b-tooltip :target="'description-tooltip-' + data.item.id">
                <span v-if="data.item.description">{{ data.item.description }}</span>
              </b-tooltip>
              <span :id="'description-tooltip-' + data.item.id">
                <i class="fa-regular fa-file-lines" style="color: #676a6c"></i>
              </span>
            </div>
          </template>
          <template #cell(locations)="data">
            <b-tooltip :target="'location-tooltip-' + data.item.id">
              <span v-if="data.item.locations.length">{{ formatLocationsTooltip(data.item.locations) }}</span>
            </b-tooltip>
            <span :id="'location-tooltip-' + data.item.id">
              <i class="fa-solid fa-location-dot" :style="data.item.locations.length ? 'color: #61AEB7' : 'color: #676a6c'"></i>
              x
              {{ data.item.locations.length }} 
            </span>
          </template>
          <template #cell(startDate)="data">
            <span v-if="data.value">
              {{ formatDate(data.value) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(endDate)="data">
            <span v-if="data.value">
              {{ formatDate(data.value) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(cost)="data">
            <span>{{ formatCost(data.item.cost) }}</span>  
            <span>{{ " "}}</span>  
            <span>{{ data.item.currency && data.item.cost ? data.item.currency : "" }}</span>
          </template>
          <template #cell(responsiblePerson)="data">
            <span>{{ data.item.responsiblePerson }}</span>
          </template>
        </b-table>
        <b-pagination
          v-if="logs.length && logs.length > table.size"
          size="sm"
          class="m-t-xs"
          align="right"
          :total-rows="logs.length"
          v-model="table.page"
          :per-page="table.size"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import RowCount from '@/components/common/RowCount';
import { BTooltip } from 'bootstrap-vue';

export default {
  components: {
    RowCount,
    BTooltip
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
    backRoute: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      table: {
        filter: '',
        fields: [
          { key: 'id', label: this.$t('orgAdmin.building.logs.logId'), sortable: true},
          { key: 'buildingName', label: this.$t('orgAdmin.building.logs.buildingName'), sortable: true },
          { key: 'title', label: this.$t('orgAdmin.building.logs.title'), sortable: true },
          { key: 'tags', label: this.$t('orgAdmin.building.logs.tags'), sortable: false },
          { key: 'description', label: this.$t('orgAdmin.building.logs.description'), sortable: false },
          { key: 'locations', label: this.$t('orgAdmin.building.logs.locations'), sortable: false },
          { key: 'startDate', label: this.$t('orgAdmin.building.logs.startDate'), sortable: true, formatter: this.formatDate },
          { key: 'endDate', label: this.$t('orgAdmin.building.logs.endDate'), sortable: true, formatter: this.formatDate },
          { key: 'cost', label: this.$t('orgAdmin.building.logs.cost'), sortable: false },
          { key: 'responsiblePerson', label: this.$t('orgAdmin.building.logs.responsiblePerson'), sortable: true },
        ],
        page: 1,
        size: 25,
        sortBy: 'id',
        sortDesc: true,
      },
      tagOptions: ['Heating', 'Water', 'Electric', "Ventilation"],
      tagDictionary: {
        Heating: this.$t('orgAdmin.building.logs.tagPresets.heating'),
        Water: this.$t('orgAdmin.building.logs.tagPresets.water'),
        Electric: this.$t('orgAdmin.building.logs.tagPresets.electricity'),
        Ventilation: this.$t('orgAdmin.building.logs.tagPresets.ventilation')
      },
      colorList: ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'],
    };
  },
  computed: {
    processedLogs() {
      return this.logs.map(log => {
        return {
          ...log,
          id: Number(log.id),
          startDate: new Date(log.startDate),
          endDate: new Date(log.endDate)
        };
      });
    },
  },
  watch: {
    logs: {
      handler(newLogs) {
        this.table.totalRows = newLogs.length;
      },
      immediate: true,
    },
  },
  methods: {
    clearFilter() {
      this.table.filter = '';
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
    formatLocationsTooltip(locations) {
      return locations.map(loc => loc?.name || "").join(', ');
    },
    formatCost(cost) {
      return cost ? `${cost.toFixed(2)}` : '-';
    },
    refreshLogs() {
      this.$emit('refreshLogs');
    },
    viewLogDetails(item) {
      this.$store.commit("buildingLogs/SET_BACK_ROUTE", this.backRoute)
      this.$store.commit('buildingLogs/SET_SELECTED_LOG', item);
      this.$router.push(`/buildings/${item.building.id}/logs/${item.id}`);
    },
    createNewLog() {
      this.$router.push(`/buildings/${this.$parent.buildingId}/logs/new`);
    },
    hashStringToNumber(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    getColorForTag(tag) {
      const predefinedColors = {
        Heating: 'red',
        Water: 'blue',
        Electric: 'gold',
        Ventilation: 'indigo',
        default: 'secondary'
      };
      if (this.tagOptions.includes(tag)){
        return predefinedColors[tag];
      }
      const hash = this.hashStringToNumber(tag);
      const index = Math.abs(hash) % this.colorList.length;
      return this.colorList[index];
    },
    getTagLabel(tag) {
      return this.tagDictionary[tag] || tag;
    },
    tagClass(tag) {
      const predefinedColors = {
        Heating: 'primary',
        Water: 'info',
        Electric: 'warning',
        default: 'secondary'
      };
      return predefinedColors[tag] || predefinedColors.default;
    },
  },
};
</script>


<style scoped>
.text-nowrap {
  white-space: nowrap;
}
.icon-wrapper {
  display: flex;
  align-items: center;
}
.download-link,
.delete-link {
  cursor: pointer;
}
.download-link i,
.delete-link i {
  margin: 0 5px;
}
.tags-circles {
  display: flex;
  align-items: center;
}
.tag-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  position: relative;
}
.tag-circle:hover .tag-tooltip {
  visibility: visible;
  opacity: 1;
}
.tag-tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the circle */
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center it */
  width: 120px;
  transition: opacity 0.3s;
}
.tag-tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
.primary {
  background-color: blue;
}
.info {
  background-color: cyan;
}
.warning {
  background-color: yellow;
}
.secondary {
  background-color: grey;
}
</style>

