<template>
  <div class="animated fadeInRight">

    <div class="row">
      <div class="col-12">
        <div class="float-right w-400">
            <resource-selector
                :resourceSelectedCallback="buildingSelectedCallback"
                :force-preselect-cascade="isBuildingAdminInContextOrg"
                :preselect-resource="contextBuilding ? { building: contextBuilding } : null"
                :selection-clearable="isContextOrgAdmin"
                selectDepth="building" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-tabs ref="tabs" @input="tabSelected" v-if="contextOrg" v-model="activeTab" content-class="px-5 py-5 white-bg">

          <b-tab :title="$t('orgAdmin.organisation.details.devices')" lazy>
            <device-list :items="devices" />
          </b-tab>

          <b-tab :title="$t('orgAdmin.organisation.details.beacons')" lazy>
            <beacon-list :items="beacons" />
          </b-tab>

          <b-tab :title="$t('orgAdmin.organisation.details.metering_points')" lazy>
            <device-list :items="meters" />
          </b-tab>

        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import BeaconList from "@/components/views/devices/BeaconList.vue";
import DeviceList from "@/components/views/devices/DeviceList.vue";
import ResourceSelector from "@/components/common/ResourceSelector.vue";

export default {
  components: {
    ResourceSelector,
    BeaconList,
    DeviceList
  },
  data() {
    return {
      error: null,
      activeTab: this.$store.state.devices.activeTab
    }
  },
  created() {
    this.fetchIfOrgChanged();
    this.$nextTick(() => {
      if (this.activeTab !== 0) {
        this.$refs.tabs.$el.querySelectorAll('.nav-link.active')[0].click()
      }
    })
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      contextBuilding: state => state.contextBuilding,
      devices: state => state.devices.devices,
      beacons: state => state.devices.beacons,
      meters: state => state.devices.meters,
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.orgAdmin.organisationDetails.entity : this.$store.state.contextOrg
    },
    building() {
      console.log('building() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.orgAdmin.buildingDetails.entity : this.$store.state.contextBuilding
    },
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        console.log("1")
        this.fetchIfOrgChanged()
      }
    },
    contextBuilding: function (newVal, oldVal) {
      if(newVal == null){
        console.log("2")
        this.fetchIfOrgChanged()
      } else if (newVal?.id !== oldVal?.id) {
        console.log("3")
        this.fetchIfBuildingChanged()
      }
    },
  },
  methods: {
    tabSelected() {
      console.log('tabSelected ' + this.activeTab)
      this.$store.commit('devices/SAVE_DEVICES_ACTIVE_TAB', this.activeTab)
      this.fetchIfOrgChanged();
    },
    fetchIfOrgChanged(){
      console.log('fetchIfOrgChanged ' + this.contextOrg?.id)
      if(this.isContextOrgAdmin) {
        switch (this.activeTab) {
          case 0:
            this.$store.dispatch('devices/fetchOrganisationDevices', this.contextOrg.id)
            break;
          case 1:
            this.$store.dispatch('devices/fetchOrganisationBeacons', this.contextOrg.id)
            break;
          case 2:
            this.$store.dispatch('devices/fetchOrganisationMeteringPoints', this.contextOrg.id)
            break;
        }
      }
      this.fetchIfBuildingChanged()
    },
    fetchIfBuildingChanged(){
      console.log('fetchIfBuildingChanged ' + this.contextBuilding?.id)
      if(this.contextBuilding && (this.isBuildingAdminInContextOrg || this.isContextOrgAdmin)) {
        switch (this.activeTab) {
          case 0:
            this.$store.dispatch('devices/fetchBuildingDevices', this.contextBuilding.id)
            break;
          case 1:
            this.$store.dispatch('devices/fetchBuildingBeacons', this.contextBuilding.id)
            break;
          case 2:
            this.$store.dispatch('devices/fetchBuildingMeteringPoints', this.contextBuilding.id)
            break;
        }
      }
    },
    buildingSelectedCallback(payload) {
      console.log('buildingSelectedCallback ' + payload.resource?.id)
      this.$store.commit('SET_CONTEXT_BUILDING', payload.resource)
    }
  }
}
</script>

<style scoped>

</style>