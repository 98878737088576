<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-6">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)" v-if="device.deviceModel != null">

                <div class="row">

                  <div class="col-12">
                    <div class="form-row form-group">
                        <span class="col-sm-3">{{ $t("systemAdmin.device.attributes.externalId") }}:</span>
                        <span class="p-1">{{ device.externalId }}</span>
                    </div>

                    <div class="form-row form-group">
                        <span class="col-sm-3">{{ $t("systemAdmin.device.attributes.deviceModel.name") }}:</span>
                        <span class="p-1">{{ device.deviceModel.manufacturer }} / {{ device.deviceModel.name }}</span>
                    </div>

                    <div class="form-row form-group">
                        <span class="col-sm-3">{{ $t("systemAdmin.device.attributes.deviceModel.type") }}::</span>
                        <span class="p-1">{{ $t("enums.DeviceModelType." + device.deviceModel.type) }}</span>
                    </div>

                    <validation-provider name="systemAdmin.device.attributes.alias" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-device-alias" :label="$t('systemAdmin.device.attributes.alias')" label-for="input-device-alias" label-cols-sm="3">
                        <b-form-input
                            :plaintext="type=='view'"
                            id="input-device-alias"
                            alias="input-device-alias"
                            v-model="form.alias"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-device-alias"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-device-alias">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-form-group v-if="device.organisation && device.deviceModel.type !== 'BLE_BEACON'" id="input-group-device-integration" :label="$t('systemAdmin.device.attributes.integration')" label-for="input-device-integration" label-cols-sm="3">
                        <v-select
                          id="input-device-integration"
                          name="input-device-integration"
                          :disabled="type=='view'"
                          v-if="type!='view' && integrationList.length > 0"
                          v-model="form.integration"
                          :options="integrationList"
                          label="alias"
                          :clearable="false"
                      />
                      <div class="mt-2" v-if="type=='view' && form.integration">{{ form.integration.alias }}</div>
                      <div class="mt-2 font-italic" v-else-if="type=='view'">{{ $t("common.notSet") }}</div>
                      <div class="mt-2 font-italic" v-else-if="type!='view' && integrationList.length === 0">{{ $t("systemAdmin.device.info.noIntegrations") }}</div>
                    </b-form-group>

                  </div>

                </div>

                <div class="row">
                  <div class="col-12">
                    <button type="button" class="btn btn-sm btn-primary" v-if="type=='view'" @click.prevent="edit">
                      <i class="fa fa-edit"></i>&nbsp;{{ $t('systemAdmin.device.actions.edit') }}
                    </button>
                    <button type="submit" v-if="type=='edit'" class="btn btn-sm btn-primary">{{ $t('common.actions.save') }}</button>
                    <button type="button" v-if="type=='edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                    <button type="button" class="btn btn-sm btn-white m-l-sm" @click="back">{{ $t('common.actions.back') }}</button>
                    <button type="button" v-if="type!='create' && hasRole('ADMIN')" class="btn btn-sm btn-danger float-right" @click="deleteDevice">{{ $t('common.actions.delete') }}</button>
                  </div>
                </div>

                <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated" appear>
                  <div class="row mt-4" v-if="device.parameters">
                    <h4 class="mb-3" v-if="device.organisation">{{ $t('systemAdmin.device.details.deviceInfo') }}:</h4>
                    <div class="col-12">
                      <div class="form-row form-group" v-if="device.parameters.signalQuality">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.signalQuality') }}:</span>
                        <span class="p-1">
                          <span class="signal-icon-wrapper">
                            <div v-for="n in 3" :key="n" :class="getSignalClass(n, device.parameters.signalQuality)"></div>
                          </span>
                        </span>
                      </div>
                      <div class="form-row form-group" v-if="device.parameters.signalStrength">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.signalStrength') }}:</span>
                        <span class="p-1">
                          <span class="signal-icon-wrapper">
                            <div v-for="n in 3" :key="n" :class="getSignalClass(n, device.parameters.signalStrength)"></div>
                          </span>
                        </span>
                      </div>
                      <div class="form-row form-group" v-if="device.parameters.signalNoise">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.signalNoise') }}:</span>
                        <span class="p-1">
                          <span class="signal-icon-wrapper">
                            <div v-for="n in 3" :key="n" :class="getSignalClass(n, device.parameters.signalNoise, true)"></div>
                          </span>
                        </span>
                      </div>
                      <div class="form-row form-group" v-if="device.parameters.averageRSSI">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.averageRSSI') }}:</span>
                        <span class="p-1">{{ parseFloat(device.parameters.averageRSSI).toFixed(2) + " dBm" }}</span>
                      </div>
                      <div class="form-row form-group" v-if="device.parameters.averageSNR">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.averageSNR') }}:</span>
                        <span class="p-1">{{ parseFloat(device.parameters.averageSNR).toFixed(2) + " dB" }}</span>
                      </div>
                      <div class="form-row form-group" v-if="device.parameters.battery">
                        <span class="col-sm-3">{{ $t('systemAdmin.device.attributes.parameters.battery') }}:</span>
                        <span class="p-1">{{ device.parameters.battery + "%"}} </span>
                      </div>
                    </div>
                  </div>
                </transition>

                <div class="row">
                  <div class="col-12">
                    <div class="mt-5">
                      <h4 class="mb-3" v-if="device.organisation">{{ $t('systemAdmin.device.details.assignedTo') }}:</h4>
                        <div class="form-row form-group" v-if="device.organisation"><span class="col-sm-3">{{ $t('systemAdmin.device.attributes.organisation') }}:</span><span>{{ device.organisation.name }}</span></div>
                        <div class="form-row form-group" v-if="device.building"><span class="col-sm-3">{{ $t('systemAdmin.device.attributes.building') }}:</span><span>{{ device.building.name }}</span></div>
                        <table class="table" v-if="device.resources && device.resources.length > 0">
                            <thead>
                              <tr>
                                <th>{{ $t('systemAdmin.device.attributes.map') }}</th>
                                <th>{{ $t('systemAdmin.device.attributes.location') }}</th>
                                <th v-if="device.resources.length > 1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="resource in device.resources" :key="resource.id">
                                <td>{{ resource.map ? resource.map.name : $t('systemAdmin.device.details.deviceResourcesList.allMaps') }}</td>
                                <td>{{ resource.location ? `${resource.location.name}${resource.location.nameAlternate ? ` (${resource.location.nameAlternate})` : ''}` : $t('systemAdmin.device.details.deviceResourcesList.allLocations') }}</td>
                                <td v-if="device.resources.length > 1">
                                  <button class="btn btn-xs btn-default m-l-xs" v-if="resource.id" @click="deleteResource(resource.id)">
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                    <button type="button" v-if="showAssign==false" class="btn btn-sm btn-primary" @click="showAssign=true">{{ $t('common.actions.' + (device.deviceModel.type!=='METERING_POINT' ? (device.resources.length > 0 ? 'reassign':'assign') : (device.resources.length > 0 ? 'add':'assign'))) }}</button>
                    <button type="button" v-if="showAssign==false && device.organisation" class="btn btn-sm btn-primary ml-2" @click="unassignDevice">{{ $t('systemAdmin.device.actions.unassign') }}</button>
                  </div>
                </div>

                <transition :duration="{ enter: 300, leave: 100 }" enter-active-class="fadeIn animated" leave-active-class="fadeOut animated" appear>
                  <div class="row" v-if="showAssign">
                    <div class="col-12">
                      <device-resource-assign
                          :preselected-resources="device.building ? { organisation: device.organisation, building: device.building } : (device.organisation ? { organisation: device.organisation } : {})"
                          :fix-preselected-resources="device.deviceModel.type==='METERING_POINT'"
                          :resource-selected-callback="singleResourceSelectedCallback"
                          :cancel-callback="cancelAssignCallback"/>
                      <hr/>
                    </div>
                  </div>
                </transition>

              </b-form>
            </validation-observer>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import DeviceResourceAssign from "@/components/views/admin/system/DeviceResourceAssign"
import Vue from "vue"
import * as commons from "@/commons"
import i18n from "@/i18n"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DeviceResourceAssign
  },
  data() {
    return {
      error: null,
      type: 'view',
      showAssign: false,
      integrationList: [],
      form: {
        alias: '',
        integration: null,
        resources: null
      }
    }
  },
  created() {
    console.log('deviceDetails $route.name: ' + this.$route.name)
    this.type = 'view'
    if(this.type == 'view') {
      this.$store.dispatch('systemAdmin/fetchDeviceDetails', this.$route.params.id)
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      device: state => state.systemAdmin.deviceDetails.entity,
    }),
  },
  watch: {
    device: function(newVal, oldVal) { // watch it
      console.log('Prop device changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    }
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    edit() {
      //load integrations for organisation
      if (this.device.organisation != null) {
        Vue.axios.get('/admin/organisations/' + this.device.organisation.id + '/integrations').then(response => {
          this.integrationList = response.data
        }).catch(error => {
          commons.processRestError(error)
        })
      }

      this.type = 'edit'
    },
    reset() {
      if (this.device) {
        this.form = Object.assign({}, this.device)
        this.type = 'view'
      } else {
        this.form.alias = ''
      }
      this.$refs.form.reset()
    },
    back() {
      this.goBack()
    },
    singleResourceSelectedCallback(callbackData) {

      let self = this
      this.showAssign = false

      // remove if same data is selected to avoid duplicates
      let updatedDeviceResources = [...this.device.resources].filter(item =>
          !(((item.organisation == null && callbackData.organisation == null) || (item.organisation != null && callbackData.organisation != null && item.organisation.id === callbackData.organisation.id))
              && ((item.building == null && callbackData.building == null) || (item.building != null && callbackData.building != null && item.building.id === callbackData.building.id))
              && ((item.map == null && callbackData.map == null) || (item.map != null && callbackData.map != null && item.map.id === callbackData.map.id))
              && ((item.location == null && callbackData.location == null) || (item.location != null && callbackData.location != null && item.location.id === callbackData.location.id))))
              .filter(item => item.building != null); //remove resource that has no building

      if(self.device.deviceModel.type === 'METERING_POINT'){
        // if floor plan is selected remove building - all floors entry if it is present in original resource list
        if(callbackData.location != null) {
          updatedDeviceResources = updatedDeviceResources.filter(item => ((item.map && item.map.id !== callbackData.map.id) || item.location !== null))
        } else if(callbackData.map != null) {
          updatedDeviceResources = updatedDeviceResources.filter(item => item.map !== null)
        }
        updatedDeviceResources = updatedDeviceResources.concat(callbackData)
      }else {
        updatedDeviceResources = [callbackData]
      }

      Vue.axios.put('/admin/devices/' + self.device.id, {
            id: self.device.id,
            integration: (self.form.organisation && callbackData.organisation.id === self.form.organisation.id) ? self.form.integration : null,
            resources: updatedDeviceResources
          },
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => {
            self.$toastr.i(self.$i18n.t('systemAdmin.device.info.assigned'))
            this.$store.dispatch('systemAdmin/fetchDeviceDetails', this.$route.params.id)
          })
          .catch(error => {
            if (error.response.status === 403) {
              self.error = self.$i18n.t('systemAdmin.device.error.assignNotAuthorized')
            } else {
              commons.processRestError(error)
            }
          })
    },
    cancelAssignCallback() {
      this.showAssign = false
    },
    submit() {
      this.error = ""
      this.info = ""
      let self = this
      if (this.type == "edit") {
        Vue.axios.put('/admin/devices/' + this.form.id, {
              id: self.form.id,
              alias: self.form.alias,
              integration: self.form.integration
            },
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('systemAdmin.device.info.saved'))
          self.$store.dispatch('systemAdmin/fetchDeviceDetails', self.$route.params.id)
          self.type = 'view'
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            self.error = commons.processRestError(error)
          }
        })
      }
    },
    unassignDevice() {
      let self = this;
      Vue.axios.put('/admin/devices/' + this.form.id, {
            id: self.form.id,
            alias: self.form.alias,
            integration: self.form.integration,
            resources: self.hasRole('ADMIN') ? [] : [{organisation: self.device.organisation}]
          },
          {
            headers: {'Content-Type': 'application/json'}
          }).then(response => {
        self.$toastr.i(i18n.t('systemAdmin.device.info.unassigned'))
        self.$store.dispatch('systemAdmin/fetchDeviceDetails', self.$route.params.id)
        self.type = 'view'
      }).catch(error => {
        if (error.response.status === 422) {
          self.$refs.form.setErrors(error.response.data)
        } else {
          self.error = commons.processRestError(error)
        }
      })
    },
    deleteDevice() {
      let self = this;
      this.confirmDialog({
        text: this.$t('systemAdmin.device.confirm.delete'),
        callbackYes: function () {
          Vue.axios.delete('/admin/devices/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('systemAdmin.device.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    deleteResource(resourceId) {

      let updatedDeviceResources = this.device.resources.filter(item => item.id !== resourceId)

      let self = this;

      this.confirmDialog({
        text: this.$t('systemAdmin.device.confirm.deleteResource'),
        callbackYes: function () {
          Vue.axios.put('/admin/devices/' + self.form.id, {
            id: self.form.id,
            integration: self.form.integration,
            resources: updatedDeviceResources
          }).then(response => {
            self.$toastr.i(i18n.t('systemAdmin.device.info.unassigned'))
            self.$store.dispatch('systemAdmin/fetchDeviceDetails', self.$route.params.id)
            self.type = 'view'
          })
        }
      })
    },
    getSignalClass(n, quality, reverseLogic = false) {
      if (quality === 'n/a') {
        return 'signal-bar signal-empty';
      }
      if (reverseLogic) {
        //this is due to signal noise being good at 1 and bad at 3
        const reverseQuality = quality == 1 ? '3' : quality == 2 ? '2' : '1';
        if (n <= reverseQuality) {
          return `signal-bar signal-${reverseQuality}`;
        } else {
          return 'signal-bar signal-empty';
        }
      } else {
        if (n <= quality) {
          return `signal-bar signal-${quality}`;
        } else {
          return 'signal-bar signal-empty';
        }
      }
    }
  }
}
</script>


<style scoped>

.signal-icon-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1.3rem;
  align-items: flex-end;
  justify-content: center;
}

.signal-1 {
  background-color: #A31621;
}

.signal-2 {
  background-color: #FFC000;
}

.signal-3 {
  background-color: #2C6E49;
}

.signal-empty {
  background-color: #E0E0E0;
}

.signal-bar {
  height: 100%;
  border-radius: 3px;
  width: 0.3rem;
  margin: 0.1rem;
}

.signal-bar:nth-of-type(1){
  height: 40%;
}
.signal-bar:nth-of-type(2){
  height: 66%;
}

</style>